import Component, { mixins } from 'vue-class-component';
import { Provide } from 'vue-property-decorator';

// @Component
// export default class Usecases extends Vue {
//   // props

//   // data()

//   // computed

//   // lifecycle hooks

//   // methods
// }
import axios from 'axios';
import operations from '@/assets/data/operations.json';
import objects from '@/assets/data/objects.json';

import AppUsecasesOrderDialog from './UsecasesOrderDialog.vue';
import AppShortRegistrationDialog from '@/components/layout/Dialogs/ShortRegistration.vue';
import RcQuestion from './Question.vue';
import Utils from './utils';
import BaseOrderMixin from '@/views/ObjectDetail/Tabs/BaseOrder.mixin';

function f(a: any, b: any) {
  for (
    let c = Utils.asArray(b[0]), e = Utils.asArray(b[1]), f = Utils.asArray(b[2]), g = 0;
    g < c.length;
    g++
  ) {
    for (let h = 0; h < e.length; h++) {
      for (let i = 0; i < f.length; i++) {
        a.push([ c[g], e[h], f[i] ]);
      }
    }
  }
}

@Component({
  components: { RcQuestion },
})
export default class Usecases extends mixins(BaseOrderMixin) {
  // data()
  productName: string = 'OrderMaintenance';
  steps: string[] = [ 'Выбор объекта', 'Выбор операции', 'Заполнение анкеты', 'Перечень документов' ];
  objects: any = objects;
  operations: any = operations;
  questions: any[] = [];
  documents: any[] = [];
  selectedObject: any = null;
  selectedOperation: any = null;
  showDocs: boolean = false;
  step: number = 1;
  @Provide()
  selectedQuestions: any = {};

  // computed
  get user(): User {
    return this.$store.state.auth.user;
  }

  get allQuestionsChecked(): boolean {
    return Object.keys(this.selectedQuestions).length === this.questionList.length;
  }

  get selectedOperationName(): string {
    if (this.selectedOperation) {
      const obj = this.operations.filter((o: any) => o.id === this.selectedOperation);

      return obj[0].name;
    }

    return '';
  }

  get selectedObjectName(): string {
    if (this.selectedObject) {
      const obj = this.objects.filter((obj: any) => obj.id === this.selectedObject);

      return obj[0].name;
    }

    return '';
  }

  get objectOperations(): any[] {
    if (!this.selectedObject || !this.operations) {
      return [];
    }

    const filtered = this.operations.filter((op: any) => {
      return op.objectIds.indexOf(this.selectedObject) !== -1;
    });

    const group = filtered.reduce((g: any, op: any) => {
      if (!g[op.category]) {
        g[op.category] = [];
      }

      g[op.category].push(op);
      return g;
    }, {});

    return group;
  }

  get requiredDocumenst(): any[] {
    const filteredDocuments = [];

    for (const document of this.documents) {
      let match = false;
      if (
        this.firstFilter(
          document,
          this.selectedObject,
          this.selectedOperation,
          'Document filter error',
        ) ||
        this.secondFilter(this.selectedQuestions, document.match, this.selectedOperation) ||
        this.thirdFilter(this.selectedQuestions, document.crossMatch, this.selectedOperation)
      ) {
        match = true;
      }

      if (match) {
        filteredDocuments.push(document);
      }
    }

    return filteredDocuments;
  }

  get questionList(): any[] {
    if (this.selectedOperation === null) {
      return [];
    }

    const filteredQuestions = [];
    for (let index = 0; index < this.questions.length; index++) {
      const question = this.questions[index];
      let match = false;

      if (Utils.isNotEmpty(question.operationIds) || Utils.isNotEmpty(question.parentMatch)) {
        if (
          this.firstFilter(
            question,
            this.selectedObject,
            this.selectedOperation,
            'Question id[' + question.id + ']',
          ) ||
          this.secondFilter(this.selectedQuestions, question.parentMatch, this.selectedOperation) ||
          this.thirdFilter(this.selectedQuestions, question.crossMatch, this.selectedOperation)
        ) {
          match = true;
        }
      }

      if (match) {
        question.sortValue = this.getSortValue(this.questions, index);
        filteredQuestions.push(question);
      }
    }

    return Utils.sort('sortValue', filteredQuestions);
  }

  // lifecycle
  created() {
    this.getJson('questions', true);

    this.getJson('documents').then((documents) => {
      const docs = this.getDocumentsWithExpandedMatchFields(documents);
      docs.sort((a, b) => {
        if (b.name.toLowerCase() > a.name.toLowerCase()) {
          return -1;
        }

        if (b.name.toLowerCase() < a.name.toLowerCase()) {
          return 1;
        }

        return 0;
      });
      this.documents = docs;
    });

    this.changeStep(1);
  }

  // methods
  changeStep(step: number) {
    if (step >= 2 && this.selectedObject === null) {
      return this.$noty.info({
        text: 'Пожалуйста, выберите объект',
      });
    }

    if (step >= 3 && this.selectedOperation === null) {
      return this.$noty.info({
        text: 'Пожалуйста, выберите операцию',
      });
    }

    this.step = step;
    if (step === 1) {
      this.selectedObject = null;
      this.selectedOperation = null;
    }

    if (step === 2) {
      this.selectedOperation = null;
    }

    if (step === 3) {
      this.showDocs = false;
    }

    if (step === 4) {
      this.showDocs = true;
    }
  }

  getJson(name: string, setResponseToData?: boolean) {
    return axios.get(`/data/${name}.json`).then((response) => {
      if (setResponseToData) {
        (this as any)[name] = response.data;
      }

      return response.data;
    });
  }

  getDocumentsWithExpandedMatchFields(a: any[]) {
    for (let b = 0; b < a.length; b++) {
      if (Utils.isNotEmpty(a[b].match)) {
        const g = [];
        for (let c = a[b].match, h = 0; h < c.length; h++) {
          Utils.containsArrayItem(c[h]) ? f(g, c[h]) : g.push(c[h]);
        }
        a[b].match = g;
      }
    }
    return a;
  }

  selectObject(id: any) {
    this.selectedObject = id;
    this.changeStep(2);
  }

  mapData(response: any) {
    return response.data;
  }

  selectOperation(id: any) {
    this.selectedOperation = id;
    this.changeStep(3);
  }

  selectQuestion(id: number, index: number) {
    this.$set(this.selectedQuestions, id, index + 1);
  }

  checkAnswers(answers: any[], questionID: number, selectedQuestionPosition: number) {
    const answerIndexes = answers[questionID];

    if (Utils.isNotEmpty(answerIndexes)) {
      if (answerIndexes === selectedQuestionPosition) {
        return true;
      }
    }
    return false;
  }

  firstFilter(question: any, selectedObjectID: any, selectedOperationID: any, errorText: any) {
    let result = false;

    if (!Utils.isNotEmpty(question.operationIds)) {
      return result;
    }

    if (
      Utils.containsArrayItem(question.operationIds) ||
      Utils.containsArrayItem(question.objectIds)
    ) {
      if (Utils.isSameLength(question.operationIds, question.objectIds)) {
        const foundIndex = Utils.getMatchedArrayIndex(question.operationIds, selectedOperationID);
        if (foundIndex >= 0) {
          result = Utils.isItemLinkedToArray(question.objectIds[foundIndex], selectedObjectID);
        }
      } else {
        console.error(
          errorText +
            ' JSON error: objectIds and operationIds nested arrays have different length!',
        );
      }
    } else {
      result =
        Utils.isItemLinkedToArray(question.operationIds, selectedOperationID) &&
        Utils.isItemLinkedToArray(question.objectIds, selectedObjectID);
    }

    return result;
  }

  secondFilter(answers: any, parentMatch: any, operationID: any) {
    let isValid = false;

    if (!Utils.isNotEmpty(parentMatch)) {
      return isValid;
    }

    for (const matchItem of parentMatch) {
      // parentMatch [operationId, questionID, selectedQuestionIndex]
      if (
        (matchItem[0] === 0 || matchItem[0] === operationID) &&
        this.checkAnswers(this.selectedQuestions, matchItem[1], matchItem[2])
      ) {
        isValid = true;
        return isValid;
      }
    }

    return isValid;
  }

  thirdFilter(answers: any, crossMatch: any, operationID: any) {
    let result = false;

    if (!Utils.isNotEmpty(crossMatch)) {
      return result;
    }

    for (let index = 0; index < crossMatch.length; index++) {
      const matchItem = crossMatch[index];

      if (matchItem[0] === 0 || matchItem[0] === operationID) {
        const questionIDArr = Utils.asArray(matchItem[1]);
        const targetIndexArr = Utils.asArray(matchItem[2]);
        if (!Utils.isSameLength(questionIDArr, targetIndexArr)) {
          console.error('crossMatch field questions and answers arrays have differenr length!');
          return result;
        }

        let m = 0;

        for (let n = 0; n < questionIDArr.length; n++) {
          for (let o = Utils.asArray(targetIndexArr[n]), p = 0; p < o.length; p++) {
            if (this.checkAnswers(answers, questionIDArr[n], o[p])) {
              m++;
              break;
            }
          }
        }

        if (m === questionIDArr.length) {
          result = true;
        }
      }
    }

    return result;
  }

  getSortValue(questoins: any, currentIndex: any) {
    let g = '';
    const question = questoins[currentIndex];

    if (!Utils.isNotEmpty(question.sort) && !Utils.isNotEmpty(question.parentMatch)) {
      console.error('Question id[' + question.id + '] have no sort or parentMatch items set!');
      return g;
    }

    if (Utils.isNotEmpty(question.parentMatch)) {
      for (const matchItem of question.parentMatch) {
        // var k = question.parentMatch[i];
        if (matchItem[0] === 0 || matchItem[0] === this.selectedOperation) {
          const foundIndex = Utils.getIndexById(questoins, matchItem[1]);
          g = this.getSortValue(questoins, foundIndex) + '.a';
          break;
        }
      }
    }
    if (g.length < 1) {
      if (!Utils.isSameLength(question.operationIds, question.sort)) {
        console.error(`
              JSON for question Id[question.id] error: sort and operationIds
              arrays have different length [sortIds.length]/[operIds.length]!`);
        return g;
      }

      const foundIndex = Utils.getMatchedArrayIndex(
        Utils.asArray(question.operationIds),
        this.selectedOperation,
      );
      const sortString = question.sort[foundIndex];

      if (Utils.isArray(sortString)) {
        const objectIds = Utils.asArray(question.objectIds[foundIndex]);

        if (Utils.isArray(objectIds) && Utils.isSameLength(sortString, objectIds)) {
          for (let p = 0; p < objectIds.length; p++) {
            if (objectIds[p] === this.selectedObject) {
              g = '' + sortString[p];
              break;
            }
          }
        } else {
          console.error(`JSON for question Id[" + question.id + "] error: sort and
                  objectIds arrays for operation idx[" + foundIndex + "] have different length!`);
        }
      } else {
        g = '' + sortString;
      }
    }

    return g;
  }

  print() {
    window.print();
  }

  showDocsTab() {
    if (!this.allQuestionsChecked) {
      return this.$noty.info({ text: 'Пожалуйста, заполните полностью анкету' });
    }

    this.showDocs = true;
    this.changeStep(4);
  }

  async orderClick() {
    this.$store.commit('order/setObjectKey', this.productName);
    this.$store.commit('order/changeItem', [ this.product.product_id ]);

    if (!this.user) {
      this.$dialog.open({
        component: AppShortRegistrationDialog,
        props: {
          onRegistration: this.orderClick,
          showInfo: false,
          emailLabel: 'E-mail для уведомлений',
          phoneLabel: 'Ваш телефон, чтобы мы могли связаться с вами',
        },
      });
    } else {
      const hasPhone = !!this.user.phone;

      if (hasPhone) {
        await this.$store.dispatch('order/send');
      }

      this.$dialog.open({
        component: AppUsecasesOrderDialog,
        props: {
          hasPhone,
        },
      });
    }
  }
}
