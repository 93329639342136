






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';

@Component({
  name: 'AppQuestion',
  props: {
    question: Object,
    index: Number,
  },
})
export default class AppQuestion extends Vue {
  // props
  question: any;
  index: number;

  @Inject()
  selectedQuestions: any;

  // lifecycle;
  beforeDestroy() {
    if (this.selectedQuestions[this.question.id]) {
      Vue.delete(this.selectedQuestions, this.question.id);
    }
  }

  // methods
  selectQuestion(answerIndex: number) {
    Vue.set(this.selectedQuestions, this.question.id, answerIndex + 1);
  }
}
