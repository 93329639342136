var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "py-2" },
    [
      _c("h6", [
        _vm._v(_vm._s(_vm.index + 1) + ": " + _vm._s(_vm.question.text))
      ]),
      _vm._l(_vm.question.answers, function(answ, i) {
        return _c(
          "div",
          { key: i, staticClass: "custom-control custom-radio" },
          [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                id: _vm.question.id + "-" + i,
                type: "radio",
                name: _vm.question.id
              },
              on: {
                change: function($event) {
                  return _vm.selectQuestion(i)
                }
              }
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: _vm.question.id + "-" + i }
              },
              [_vm._v(_vm._s(answ))]
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }