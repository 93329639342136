var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "usecases pb-5" }, [
    _c("div", { staticClass: "usecases-steps d-print-none" }, [
      _c(
        "div",
        {
          staticClass: "usecases-step-item",
          class: { active: _vm.step === 1, complete: _vm.step > 1 },
          on: {
            click: function($event) {
              return _vm.changeStep(1)
            }
          }
        },
        [_vm._v("\n      1. Выбор объекта\n    ")]
      ),
      _c(
        "div",
        {
          staticClass: "usecases-step-item",
          class: { active: _vm.step === 2, complete: _vm.step > 2 },
          on: {
            click: function($event) {
              return _vm.changeStep(2)
            }
          }
        },
        [_vm._v("\n      2. Выбор операции\n    ")]
      ),
      _c(
        "div",
        {
          staticClass: "usecases-step-item",
          class: { active: _vm.step === 3, complete: _vm.step > 3 },
          on: {
            click: function($event) {
              return _vm.changeStep(3)
            }
          }
        },
        [_vm._v("\n      3. Заполнение анкеты\n    ")]
      ),
      _c(
        "div",
        {
          staticClass: "usecases-step-item",
          class: { "active complete": _vm.step === 4 }
        },
        [_vm._v("\n      4. Перечень документов\n    ")]
      )
    ]),
    _c("div", { staticClass: "page-wrapper" }, [
      _c("h3", { staticClass: "d-print-block mb-3 d-none" }, [
        _vm._v("\n      Жизненные ситуации\n    ")
      ]),
      _c("div", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectedOperation === null,
                expression: "selectedOperation === null"
              }
            ]
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectedObject === null,
                    expression: "selectedObject === null"
                  }
                ]
              },
              [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "usecases-list" },
                  _vm._l(_vm.objects, function(obj, index) {
                    return _c(
                      "div",
                      {
                        key: obj.id,
                        staticClass: "usecases-list__item",
                        on: {
                          click: function($event) {
                            return _vm.selectObject(obj.id)
                          }
                        }
                      },
                      [
                        _c("b", [_vm._v(_vm._s(index + 1) + ".")]),
                        _vm._v(" " + _vm._s(obj.name) + "\n            ")
                      ]
                    )
                  }),
                  0
                )
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectedObject !== null,
                    expression: "selectedObject !== null"
                  }
                ]
              },
              [
                _c(
                  "div",
                  { staticClass: "usecases-head mb-0 wrapper-padding_sm" },
                  [
                    _vm._m(1),
                    _c(
                      "div",
                      { staticClass: "usecases-head__btn" },
                      [
                        _c(
                          "app-button",
                          {
                            attrs: { variant: "primary-light", size: "sm" },
                            on: {
                              click: function($event) {
                                return _vm.changeStep(1)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-arrow-left fa-xs" }),
                            _vm._v(" Назад\n              ")
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c("div", [
                  _vm.objectOperations[1]
                    ? _c(
                        "div",
                        {
                          staticClass: "usecases-list",
                          class: { "border-bottom": _vm.objectOperations[0] }
                        },
                        [
                          _c("h6", { staticClass: "usecases-list__title" }, [
                            _vm._v(
                              "\n                Регистрация прав:\n              "
                            )
                          ]),
                          _vm._l(_vm.objectOperations[1], function(op) {
                            return _c(
                              "div",
                              {
                                key: op.id,
                                staticClass: "usecases-list__item",
                                on: {
                                  click: function($event) {
                                    return _vm.selectOperation(op.id)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(op.name) +
                                    "\n              "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.objectOperations[0]
                    ? _c(
                        "div",
                        { staticClass: "usecases-list" },
                        [
                          _c("h6", { staticClass: "usecases-list__title" }, [
                            _vm._v(
                              "\n                Кадастровый учёт:\n              "
                            )
                          ]),
                          _vm._l(_vm.objectOperations[0], function(op) {
                            return _c(
                              "div",
                              {
                                key: op.id,
                                staticClass: "usecases-list__item",
                                on: {
                                  click: function($event) {
                                    return _vm.selectOperation(op.id)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(op.name) +
                                    "\n              "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ])
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectedOperation !== null,
                expression: "selectedOperation !== null"
              }
            ]
          },
          [
            _vm.questionList
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showDocs,
                        expression: "!showDocs"
                      }
                    ]
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "usecases-head wrapper-padding_sm" },
                      [
                        _vm._m(2),
                        _c(
                          "div",
                          { staticClass: "usecases-head__btn" },
                          [
                            _c(
                              "app-button",
                              {
                                attrs: { variant: "primary-light", size: "sm" },
                                on: {
                                  click: function($event) {
                                    return _vm.changeStep(2)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-arrow-left fa-xs"
                                }),
                                _vm._v(" Назад\n              ")
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c("div", { staticClass: "page-wrapper_sm pt-0" }, [
                      _c(
                        "ul",
                        { staticClass: "list-unstyled mb-0" },
                        _vm._l(_vm.questionList, function(q, index) {
                          return _c("rc-question", {
                            key: q.id,
                            attrs: {
                              question: q,
                              index: index,
                              "selected-questions": _vm.selectedQuestions
                            },
                            on: {
                              "update:selectedQuestions": function($event) {
                                _vm.selectedQuestions = $event
                              },
                              "update:selected-questions": function($event) {
                                _vm.selectedQuestions = $event
                              }
                            }
                          })
                        }),
                        1
                      )
                    ]),
                    _c("div", { staticClass: "u-footer wrapper-padding_sm" }, [
                      _c(
                        "div",
                        { staticClass: "u-footer-order" },
                        [
                          _c(
                            "app-button",
                            {
                              attrs: {
                                loading: _vm.orderRequest,
                                variant: "success",
                                size: "sm"
                              },
                              on: { click: _vm.orderClick }
                            },
                            [
                              _vm._v(
                                "\n                Заказать за 990руб.\n              "
                              )
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              "\n                Помощь в оформлении документов\n              "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "u-footer__right" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: {
                                    content:
                                      "Для того, чтобы сформировать документы, необходимо ответить на все вопросы"
                                  },
                                  expression:
                                    "{\n                  content:\n                    'Для того, чтобы сформировать документы, необходимо ответить на все вопросы',\n                }"
                                }
                              ],
                              staticClass: "u-footer__icon"
                            },
                            [
                              _c("svg", [
                                _c("use", {
                                  attrs: { "xlink:href": "#icon-outline-info" }
                                })
                              ])
                            ]
                          ),
                          _c(
                            "app-button",
                            {
                              attrs: { size: "sm" },
                              on: { click: _vm.showDocsTab }
                            },
                            [
                              _vm._v(
                                "\n                Сформировать документы\n              "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showDocs,
                    expression: "showDocs"
                  }
                ],
                staticClass: "d-print-block"
              },
              [
                _c("div", { staticClass: "page-section docs-section" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "usecases-head d-print-none wrapper-padding_sm"
                    },
                    [
                      _vm._m(3),
                      _c(
                        "div",
                        { staticClass: "usecases-head__btn" },
                        [
                          _c(
                            "app-button",
                            {
                              attrs: { variant: "primary-light", size: "sm" },
                              on: {
                                click: function($event) {
                                  return _vm.changeStep(3)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-arrow-left fa-xs"
                              }),
                              _vm._v(" Назад\n                ")
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c("div", { staticClass: "d-none d-print-block" }, [
                    _vm.selectedObject
                      ? _c("h6", [
                          _vm._v(
                            "\n                Объект: " +
                              _vm._s(_vm.selectedObjectName) +
                              "\n              "
                          )
                        ])
                      : _vm._e(),
                    _vm.selectedOperation
                      ? _c("h6", [
                          _vm._v(
                            "\n                Операция: " +
                              _vm._s(_vm.selectedOperationName) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "page-wrapper_sm pt-0" }, [
                    _c(
                      "ol",
                      { staticClass: "list-unstyled u-docs" },
                      _vm._l(_vm.requiredDocumenst, function(doc, i) {
                        return _c("li", { key: i }, [
                          _c("b", { staticClass: "mr-2 float-left" }, [
                            _vm._v(_vm._s(i + 1) + ".")
                          ]),
                          _c("div", {
                            domProps: { innerHTML: _vm._s(doc.name) }
                          })
                        ])
                      }),
                      0
                    ),
                    _vm._m(4)
                  ]),
                  _c(
                    "div",
                    { staticClass: "u-footer d-print-none wrapper-padding_sm" },
                    [
                      _c(
                        "div",
                        { staticClass: "u-footer-order" },
                        [
                          _c(
                            "app-button",
                            {
                              attrs: {
                                loading: _vm.orderRequest,
                                variant: "success",
                                size: "sm"
                              },
                              on: { click: _vm.orderClick }
                            },
                            [
                              _vm._v(
                                "\n                  Заказать за 990руб.\n                "
                              )
                            ]
                          ),
                          _c("span", [_vm._v("Помощь в оформлении документов")])
                        ],
                        1
                      ),
                      _c(
                        "app-button",
                        { attrs: { size: "sm" }, on: { click: _vm.print } },
                        [
                          _vm._v(
                            "\n                Распечатать\n              "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "usecases-head mb-0 wrapper-padding_sm" }, [
      _c("div", { staticClass: "usecases-head__title" }, [
        _c("h5", [_vm._v("\n                Выберите объект\n              ")]),
        _c("small", { staticClass: "text-muted d-block" }, [
          _vm._v(
            "\n                операцию по которому вы планируете совершить\n              "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "usecases-head__title" }, [
      _c("h5", [_vm._v("\n                Выберите операцию\n              ")]),
      _c("small", { staticClass: "text-muted d-block" }, [
        _vm._v(
          "\n                которую вы планируете совершить\n              "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "usecases-head__title" }, [
      _c("h5", [_vm._v("\n                Заполните анкету\n              ")]),
      _c("small", { staticClass: "text-muted d-block" }, [
        _vm._v(
          "\n                Для того, чтобы правильно сформировать пакет необходимых для предоставления услуги\n                документов, Вам необходимо уточнить следующую информацию\n              "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "usecases-head__title" }, [
      _c("h5", [
        _vm._v("\n                  Перечень документов\n                ")
      ]),
      _c("small", { staticClass: "text-muted d-block" }, [
        _vm._v(
          "\n                  В этом разделе подобран комплект документов на основании Ваших ответов на\n                  вопросы в предыдущих этапах\n                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-none d-print-block pt-4 mt-4 border-top" },
      [
        _c("h5", { staticClass: "text-center" }, [
          _vm._v(
            "\n                  Дорогой, клиент! Мы благодарны вам за пользование нашим сервисом.\n                "
          )
        ]),
        _c("h6", { staticClass: "text-center mb-4" }, [
          _vm._v(
            "\n                  В знак признательности хотим сказать, что для вас мы приготовили следующие\n                  услуги, которые возможно будут вам интересны:\n                "
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6 mb-4" }, [
            _vm._v(
              "\n                    1. Вы можете заказать отчет о рисках по объекту недвижимости (этот отчет нужен\n                    для понимания, является ли объект безопасным для сделки и дает картину по\n                    правообладателю - возникает ли риск при заключении сделки с ним) "
            ),
            _c("br")
          ]),
          _c("div", { staticClass: "col-6 mb-4" }, [
            _vm._v(
              "\n                    2. Вы можете заказать сопровождение сделки купли-продажи недвижимости. Наши\n                    специалисты проверят объект, его собственника, сформируют для вас полный пакет\n                    документов для сделки. Вы будете уверены, что сделка пройдет безопасно. "
            ),
            _c("br")
          ]),
          _c("div", { staticClass: "col-6" }, [
            _vm._v(
              "\n                    3. Если вы хотите, мы можем подготовить документы для сделки в рамках\n                    отдельной услуги. "
            ),
            _c("br")
          ]),
          _c("div", { staticClass: "col-6" }, [
            _vm._v(
              "\n                    4. Не знаете сколько реально стоит объект? Закажите отчет об оценке рыночной\n                    стоимости.\n                  "
            )
          ])
        ]),
        _c("div", { staticClass: "row justify-content-center" }, [
          _c(
            "div",
            { staticClass: "small text-center text-muted mt-4 col-6" },
            [
              _vm._v(
                "\n                    Для заказа любой услуги вы можете написать нам на email\n                    "
              ),
              _c("span", { staticClass: "font-weight-500" }, [
                _vm._v("001@realtycloud.ru")
              ]),
              _vm._v(
                " или позвонить по\n                    телефону\n                    "
              ),
              _c("span", { staticClass: "font-weight-500" }, [
                _vm._v("+7 (962) 542-38-11")
              ])
            ]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }