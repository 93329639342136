



































import Vue from 'vue';
import Component from 'vue-class-component';
import { Validators } from '@/core/utils/validator';

@Component({
  name: 'AppUsecasesOrderDialog',

  popupOptions: {
    size: 'xs',
  },

  props: {
    hasPhone: Boolean,
  },
})
export default class UsecasesOrderDialog extends Vue {
  // data()
  success: boolean = false;
  model = {
    phone: '',
  };

  // computed

  get user(): User {
    return this.$store.state.admin.user;
  }

  // methods
  async checkout() {
    this.user.phone = this.model.phone;
    try {
      await this.$store.dispatch('user/updateInfo', this.user);
      await this.$store.dispatch('order/send');
    } catch (error) {
      console.error(error);
    }

    this.success = true;
  }

  validationRules(): ValidationConfig {
    return {
      phone: [ Validators.required, Validators.phone ],
    };
  }
}
