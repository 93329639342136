interface ItemWithId {
  id?: string | number;
}

// declare var angular: any;

const Utils = {
  isDefined(item: any) {
    return typeof item !== 'undefined';
  },

  isArray(arrayLikeItem: any) {
    return Array.isArray(arrayLikeItem);
  },

  asArray(el: any) {
    return Array.isArray(el) ? el : [ el ];
  },

  isSameLength(a: any, b: any): boolean {
    return Utils.isDefined(a) && Utils.isDefined(b) && a.length === b.length;
  },

  getMatchedArrayIndex(a: any, b: any): number {
    for (let i = 0; i < a.length; i++) {
      if (this.isItemLinkedToArray(this.asArray(a[i]), b)) {
        return i;
      }
    }

    return -1;
  },

  isItemLinkedToArray(array: any, element: number): boolean {
    if (!Utils.isDefined(array) || (array.length === 1 && array[0] === 0)) {
      return true;
    }

    for (const arrItem of array) {
      if (arrItem === element) {
        return true;
      }
    }

    return false;
  },

  getIndexById(array: ItemWithId[], targetId: number): number | null {
    if (!array) {
      return -1;
    }

    for (let index = 0; index < array.length; index++) {
      if (array[index].id === targetId) {
        return index;
      }
    }

    return null;
  },

  containsArrayItem(array: any[]) {
    if (Array.isArray(array)) {
      return array.some(Array.isArray);
    }

    return false;
  },

  sort(sortKeys: any, questions: any) {
    sortKeys = sortKeys.split('.');
    const keysLength = sortKeys.length;

    questions.sort(function(prevQuestion: any, nextQuestion: any) {
      for (let i = 0; keysLength > i; i++) {
        prevQuestion = prevQuestion[sortKeys[i]];
        nextQuestion = nextQuestion[sortKeys[i]];
      }

      return nextQuestion > prevQuestion ? -1 : prevQuestion > nextQuestion ? 1 : 0;
    });

    return questions;
  },

  isNotEmpty(a: any): boolean {
    return typeof a !== 'undefined' && a !== null;
  },
};

export default Utils;
